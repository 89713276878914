// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { SxProps, TextField, styled } from '@mui/material'

export const StandardTextField = styled(TextField)((sx?: SxProps) => ({
  fontSize: 16,
  '&.MuiTextField-root': {
    outline: 'none',
    '& fieldset': {
      borderColor: 'rgba(0,0,0,0.3)',
    },
    height: '40px',
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    color: '#656565',
  },
  '& .MuiOutlinedInput-input': {
    height: '40px',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'none',
  },
  'MuiOutlinedInput-root': {
    outline: 'none',
    fontSize: '14px',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingLeft: '4px',
  },
}))

export const StandardRoundedTextField = styled(TextField)((sx?: SxProps) => ({
  fontSize: '16px',
  borderRadius: 8,
  '& .MuiInputBase-root': {
    fontSize: '16px',
  },
  '&.MuiTextField-root': {
    outline: 'none',
    '& fieldset': {
      borderColor: 'rgba(0,0,0,0.3)',
    },
    height: '52px',
    zIndex: 1,
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    color: '#656565',
  },
  '& .MuiOutlinedInput-input': {
    height: '52px',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'none',
  },
  'MuiOutlinedInput-root': {
    outline: 'none',
    fontSize: '20px',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingLeft: '4px',
    fontSize: '20px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
  },
  '& label.Mui-focused': {
    color: 'gray',
    fontSize: '20px',
  },
  '& label.MuiInputLabel-shrink': {
    color: 'gray',
    fontSize: '20px',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.8)',
  },
}))

export const SmallStandardRoundedTextField = styled(TextField)((sx?: SxProps) => ({
  fontSize: '16px',
  borderRadius: 8,
  '& .MuiInputBase-root': {
    fontSize: '16px',
  },
  '&.MuiTextField-root': {
    outline: 'none',
    '& fieldset': {
      borderColor: 'rgba(0,0,0,0.3)',
    },
    height: '40px',
    zIndex: 1,
  },
  '& .MuiFormLabel-root': {
    fontSize: '14px',
    color: '#656565',
  },
  '& .MuiOutlinedInput-input': {
    height: '40px',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: 'none',
  },
  'MuiOutlinedInput-root': {
    outline: 'none',
    fontSize: '20px',
  },
  '& .MuiInputLabel-outlined.Mui-focused': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
    outline: 'none',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingLeft: '4px',
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
  },
  '& label.Mui-focused': {
    color: 'gray',
    fontSize: '16px',
  },
  '& label.MuiInputLabel-shrink': {
    color: 'gray',
    fontSize: '16px',
  },
}))
