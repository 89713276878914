import { WaitForQueries } from '@graphcommerce/ecommerce-ui'
import { CartFabProps } from '@graphcommerce/magento-cart'
import { CartTotalQuantityFragment } from '@graphcommerce/magento-cart/components/CartFab/CartTotalQuantity.gql'
import { useCartQuery } from '@graphcommerce/magento-cart/hooks/useCartQuery'
import { Trans } from '@lingui/react'
import { SwipeableDrawer } from '@mui/material'
import { useContext } from 'react'
import { Icon } from '../Layout/Icon'
import { globalContext } from '../NextUi/globalContext'
import { CartFabDocument } from './CartFab.gql'
import { Minicart } from './Minicart'

type CartFabContentProps = CartTotalQuantityFragment

function CartFabContent(props: CartFabContentProps) {
  const { total_quantity } = props
  const { drawer, setDrawer } = useContext(globalContext)

  return (
    <div className='relative'>
      <button type='button' onClick={() => setDrawer({ id: 'cart' })}>
        <div className='flex items-center justify-center'>
          <div className={`group relative w-[50px] ${total_quantity > 0 ? 'pt-[10px]' : ''}`}>
            <Icon
              name='shopping_cart'
              className='z-5 group cursor-pointer align-bottom text-4xl text-tight-black group-hover:text-70-grey'
            />
            {total_quantity > 0 && (
              <div className='group absolute right-0 top-0 z-0 flex h-[20px] w-[20px] items-center justify-center rounded-[50%] bg-material-ui-blue text-center group-hover:bg-material-ui-dark-blue'>
                <span className='text-[12px] font-bold text-pure-white'>{total_quantity}</span>
              </div>
            )}
          </div>
        </div>
      </button>
      <SwipeableDrawer
        anchor='right'
        onClose={() => setDrawer({ id: null })}
        onOpen={() => {}}
        open={drawer.id === 'cart'}
        classes={{
          paper: 'rounded-l-[10px] max-w-[520px] bg-lightest-gray',
        }}
      >
        <Minicart onClose={() => setDrawer({ id: null })} />
      </SwipeableDrawer>
    </div>
  )
}

/**
 * We give CartFab a bit of special handling. We don't want to make requests for this component
 * whilly nilly. We've imposed some limitations:
 *
 * We use useCartQuery that means that this will only execute when there is a cartId.
 *
 * We use fetchPolicy 'cache-only' so that when the cart comes into existence it will not
 * immediately start fetching. Why? There is a time between creating a cart and adding the first
 * product to the cart. This would mean that it would immediately start executing this query.
 */
export function CartFab(props: CartFabProps) {
  const cartQuery = useCartQuery(CartFabDocument, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <WaitForQueries waitFor={cartQuery} fallback={<CartFabContent {...props} total_quantity={0} />}>
      <CartFabContent total_quantity={cartQuery.data?.cart?.items?.length ?? 0} {...props} />
    </WaitForQueries>
  )
}
