import { useApolloClient } from '@graphcommerce/graphql'
import { CustomerTokenDocument } from '@graphcommerce/magento-customer'
import { Trans } from '@lingui/react'
import Image from 'next/image'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '../../Button'
import { Message } from '../../Message'
import { globalContext } from '../../NextUi/globalContext'
import { Social } from './SignInSocial'
import google from './google.svg'

export function SignInGoogle(props: Social) {
  const { url } = props
  const client = useApolloClient()
  const { setDrawer, drawer, setRedirectAfterSignIn } = useContext(globalContext)
  const [error, setError] = useState<string | null>()

  const handleMessageEvent = useCallback(
    (event) => {
      if (event.data?.result === 1 && event.data?.token) {
        client.cache.writeQuery({
          query: CustomerTokenDocument,
          data: {
            customerToken: {
              token: event.data?.token,
              __typename: 'CustomerToken',
              createdAt: new Date().toUTCString(),
              valid: true,
            },
          },
        })

        if (drawer.id === 'auth' && typeof drawer.params?.redirectAfterSignIn === 'string') {
          const redirectUrl = drawer.params.redirectAfterSignIn
          setDrawer({ id: false })
          setRedirectAfterSignIn(redirectUrl)
        }
      } else if (event.data?.messages?.at(0)) {
        setError(event.data?.messages?.at(0) as string)
      }
    },
    [drawer],
  )

  const createPopupWin = useCallback((pageURL, pageTitle, popupWinWidth, popupWinHeight) => {
    const left = (screen.width - popupWinWidth) / 2
    const top = (screen.height - popupWinHeight) / 4

    window.open(
      pageURL,
      pageTitle,
      `resizable=yes, width=${popupWinWidth}, height=${popupWinHeight}, top=${top}, left=${left}`,
    )
  }, [])

  useEffect(() => {
    window?.addEventListener('message', handleMessageEvent, false)
    return () => {
      window?.removeEventListener('message', handleMessageEvent, false)
    }
  }, [handleMessageEvent])

  if (!url) {
    return null
  }

  return (
    <div className='flex-row items-center justify-center text-center'>
      <Button
        label={
          <>
            <Image
              className='mr-[12px] inline-block h-[14px] max-h-[14px]'
              alt='Google Sign In'
              src={google}
            />
            <Trans id='Sign in with Google' />
          </>
        }
        onClick={() =>
          createPopupWin(`${url}&generateToken=true&isAjax=1`, 'Google Sign In', 450, 450)
        }
        color='white'
        className='w-full max-w-[260px] rounded-[8px] border-[1px]  border-[#A95CFF] py-0 hover:border-[#613394]'
      />
      {error && (
        <Message type='error' content={error} containerClassName='my-[15px] max-w-[275px] w-full' />
      )}
    </div>
  )
}
