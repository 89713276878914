import { i18n } from '@lingui/core'
import { Modal } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { PrimaryButton } from '../Button'
import { SlideinModalHeader } from '../Layout/SlideinModalHeader'

export type AlertPromptProps = {
  title: string
  message: string
  isDisplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
  callback?: () => void
}

export function AlertPrompt(props: AlertPromptProps) {
  const { title, message, isDisplayed, setIsDisplayed, callback } = props

  const handleClose = (event?, reason?) => {
    if (reason && reason === 'backdropClick') return

    if (callback) {
      callback()
    }
    setIsDisplayed(false)
  }

  return (
    <Modal className='z-100 ' open={isDisplayed} onClose={handleClose}>
      <div className='z-100 absolute left-[5vw] top-[20vh] flex h-auto w-[90vw] flex-col items-center rounded-[4px] bg-pure-white shadow-md md:left-[30vw] md:w-[40vw]'>
        <SlideinModalHeader
          title={title}
          className='mb-[20px] w-full rounded-t-[4px]'
          titleClassName='Type-XXL-Bold'
          withLeftPadding
          withHorizontalMargin
        />
        <p className='mb-[40px] px-[20px]'>{message}</p>
        <div className='mb-[40px] flex w-[100%] items-center justify-center'>
          <PrimaryButton
            type='button'
            className='w-[90%] max-w-[350px] md:w-auto'
            onClick={handleClose}
          >
            {i18n._('Ok')}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}
