export type SectionTitleProps = {
  className?: string
  title?: string
  withHorizontalDivider?: boolean
  withNoBottomMargin?: boolean
}

export function SectionTitle(props: SectionTitleProps) {
  const { className, title, withHorizontalDivider = true, withNoBottomMargin } = props
  return (
    <div className={`${className}`}>
      <span className='Type-XXXL-Regular w-full '>{title}</span>
      {withHorizontalDivider && (
        <hr className={`${withNoBottomMargin ? '' : 'mb-7'}  mt-2 text-20-grey`} />
      )}
    </div>
  )
}
