import { FormControl, InputLabel, SxProps } from '@mui/material'
import { ReactNode } from 'react'
import {
  DualValueRoundedSelect,
  DualValueSelect,
  StandardRoundedSelect,
  StandardSelect,
} from './DropdownStyles'

export type StandardDropdownProps = {
  value?: any
  label?: string
  required?: boolean
  options?: ReactNode
  onChange?: (option: any | any[]) => void
  error?: boolean
  className?: string
  twoColumns?: boolean
  placeholder?: string
  sx?: SxProps
  fullWidth?: boolean
  disabled?: boolean
  defaultValue?: any
}

export function StandardDropdown(props: StandardDropdownProps, ...otherProps) {
  const {
    value,
    label,
    required,
    options,
    onChange,
    error,
    className,
    twoColumns,
    placeholder,
    sx,
    fullWidth,
    disabled,
    defaultValue,
  } = props
  return (
    <FormControl fullWidth={fullWidth} size='small'>
      <InputLabel className='flex items-center justify-center text-[14px]'>{label}</InputLabel>
      {twoColumns ? (
        <DualValueSelect
          {...otherProps}
          value={value}
          label={label}
          required={required}
          error={error}
          onChange={onChange}
          size='small'
          className={className}
          placeholder={placeholder}
          sx={sx}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {options}
        </DualValueSelect>
      ) : (
        <StandardSelect
          {...otherProps}
          value={value}
          label={label}
          required={required}
          error={error}
          onChange={onChange}
          size='small'
          className={className}
          placeholder={placeholder}
          sx={sx}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {options}
        </StandardSelect>
      )}
    </FormControl>
  )
}

export function StandardRoundedDropdown(props: StandardDropdownProps, ...otherProps) {
  const {
    value,
    label,
    required,
    options,
    onChange,
    error,
    className,
    twoColumns,
    placeholder,
    sx,
    fullWidth,
    disabled,
    defaultValue,
  } = props
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel
        className='text-gray flex items-center justify-center text-[16px]'
        sx={{
          '&.MuiInputLabel-root': {
            fontSize: '15px',
            color: 'gray',
          },
          '&.MuiInputLabel-shrink': {
            fontSize: '20px',
            color: 'gray',
          },
          '&.Mui-focused': {
            fontSize: '20px',
            color: 'gray',
          },
        }}
      >
        {label}
      </InputLabel>
      {twoColumns ? (
        <DualValueRoundedSelect
          {...otherProps}
          value={value}
          label={label}
          required={required}
          error={error}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          sx={sx}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {options}
        </DualValueRoundedSelect>
      ) : (
        <StandardRoundedSelect
          {...otherProps}
          value={value}
          label={label}
          required={required}
          error={error}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          sx={sx}
          disabled={disabled}
          defaultValue={defaultValue}
        >
          {options}
        </StandardRoundedSelect>
      )}
    </FormControl>
  )
}
