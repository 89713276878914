import { useFormAutoSubmit } from '@graphcommerce/ecommerce-ui'
import { useCartQuery, useFormGqlMutationCart } from '@graphcommerce/magento-cart'
import { CartItemFragment, CartItemProps } from '@graphcommerce/magento-cart-items'
import { MenuItem } from '@mui/material'
import { StandardDropdown } from '../../Dropdown/StandardDropdown'
import { AvailableQuantitiesDocument } from './AvailableQuantities.gql'
import { UpdateItemQuantityDocument } from './UpdateItemQuantity.gql'
import { Message } from '../../Message'
import { Trans } from '@lingui/react'

export function QuantityUpdate(props: CartItemFragment & CartItemProps & { className?: string }) {
  const { uid, quantity, className = '' } = props

  const { data, loading } = useCartQuery(AvailableQuantitiesDocument, {
    allowUrl: true,
    variables: {
      cartItemUid: uid,
    },
    skip: !uid,
  })

  const form = useFormGqlMutationCart(UpdateItemQuantityDocument, {
    defaultValues: {
      itemUid: uid,
      quantity,
    },
    mode: 'onChange',
  })
  const { handleSubmit, register, setValue, watch } = form

  const submit = handleSubmit(() => {})
  useFormAutoSubmit({ form, submit })

  const breakpointMenuItems = data?.fourOverCartItemAvailableQuantities
    ?.filter((option) => option)
    .map((option) => {
      const unitPrice = (option?.price || 0) / parseInt(option?.quantity as unknown as string, 10)
      const price = parseFloat(unitPrice.toFixed(unitPrice >= 1 ? 2 : 4))
      return (
        <MenuItem
          value={option?.quantity as number}
          className='flex justify-between pr-5'
          sx={{ justifyContent: 'space-between' }}
        >
          {[option?.quantity?.toLocaleString() || '', (price || 0) as unknown as number]?.map(
            (item, index) => (index === 0 ? <span>{item}</span> : <span>{`$${item} each`}</span>),
          )}
        </MenuItem>
      )
    })
  return (
    <div className={className}>
      <form onSubmit={submit} noValidate>
        <input type='hidden' {...register('quantity')} />
      </form>
      <StandardDropdown
        value={watch('quantity')}
        label='Quantity'
        onChange={(selectedOption) => {
          if (selectedOption && selectedOption?.target?.value)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setValue('quantity', parseFloat(selectedOption?.target?.value?.toString()), {
              shouldDirty: true,
            })
        }}
        options={breakpointMenuItems}
        twoColumns
        fullWidth
      />
      {form?.data?.update4OverItemQuantity?.turnaroundTimeUpdated && (
        <Message
          type='notice'
          content={
            <Trans
              id={'We’ve adjusted the Turn Around Time to fit your updated quantity selection'}
            />
          }
          containerClassName='my-[15px]'
        />
      )}
    </div>
  )
}
