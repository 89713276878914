import PageLink from 'next/link'
import { Button, PrimaryButton } from '../../Button'
import { SecondaryHeaderButton } from '../../Button/ButtonStyles'
import { HeroBanner } from './HeroBanner'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { banners, headerText, pageLinks, headerTitle } = props
  return (
    <HeroBanner
      pageLinks={pageLinks.map((pageLink, index) => (
        <PageLink key={pageLink.url} href={pageLink.url} passHref>
          {index === 0 && (
            <PrimaryButton
              type='button'
              className='w-auto bg-new-templi-purple normal-case'
              size='small'
            >
              {pageLink.title}
            </PrimaryButton>
          )}
          {index > 0 && (
            <SecondaryHeaderButton
              type='button'
              className='w-auto bg-secondary-templi-yellow normal-case'
              variant='contained'
              size='small'
            >
              {pageLink.title}
            </SecondaryHeaderButton>
          )}
        </PageLink>
      ))}
      headerText={headerText}
      headerTitle={headerTitle}
      banners={banners}
    />
  )
}
