import { Paper } from '@mui/material'
import { useRouter } from 'next/router'
import { RelatedProductGroups } from '../Amasty/RelatedProductGroups'
import { PrimaryButton } from '../Button'

export function NewItems() {
  const router = useRouter()
  const handleClick = async (href: string) => {
    await router.push(href)
  }

  return (
    <div className='mb-[20px] flex w-full items-center justify-center'>
      <Paper className='mx-[10px] max-w-maxContentWidth rounded-md shadow-md md:mx-[20px]'>
        <RelatedProductGroups
          position='home_top'
          classes={{
            list: 'grid md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 lg:gap-[20px] grid-cols-2 gap-[10px]',
          }}
        />
        <div className='my-6 flex w-full items-center justify-center'>
          <PrimaryButton onClick={() => handleClick('/search/q/sort/new/dir/desc')}>
            View All
          </PrimaryButton>
        </div>
      </Paper>
    </div>
  )
}
