import { assertFormGqlOperation, UseFormReturn } from '@graphcommerce/react-hook-form'
import { Trans } from '@lingui/react'
import { useEffect } from 'react'
import { StandardRoundedTextField } from '../../TextInput/TextFieldStyles'

type NameFieldValues = {
  firstname?: string
  lastname?: string
  prefix?: string
}

type NameFieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>
  readOnly?: boolean
  prefix?: boolean
  prefixes?: string[]
  required?: boolean
  isFullWidth?: boolean
  noPadding?: boolean
  columnDisplay?: boolean
  className?: string
  formDefaults?: any
  isBillingAddress?: boolean
  isEditForm?: boolean
}

export function NameFields(props: NameFieldProps) {
  const {
    form,
    readOnly,
    required,
    isFullWidth,
    noPadding,
    columnDisplay = false,
    className,
    formDefaults,
    isBillingAddress,
    isEditForm,
  } = props
  assertFormGqlOperation<NameFieldValues>(form)

  const { formState, register, valid } = form

  useEffect(() => {
    if (isBillingAddress && !isEditForm) {
      form.setValue('firstname', formDefaults?.firstname as string)
      form.setValue('lastname', formDefaults?.lastname as string)
    }
  }, [])

  return (
    <div
      className={`flex w-full flex-col items-center gap-x-5 md:items-start ${
        columnDisplay ? 'gap-y-6' : 'gap-y-5 md:flex-row'
      } ${className}`}
    >
      <StandardRoundedTextField
        label={<Trans id='First name' />}
        variant='outlined'
        disabled={readOnly}
        error={!!formState.errors.firstname}
        required={required}
        {...register('firstname', {
          required: true,
        })}
        value={form.getValues()?.firstname ?? ''}
        className='min-w-[260px]'
        sx={{
          width: '260px',
          minWidth: '260px',
          zIndex: 2,
        }}
      />
      <StandardRoundedTextField
        label={<Trans id='Last name' />}
        variant='outlined'
        disabled={readOnly}
        error={!!formState.errors.lastname}
        {...register('lastname', {
          required: true,
        })}
        value={form.getValues()?.lastname ?? ''}
        className='min-w-[260px]'
        sx={{
          width: '260px',
          minWidth: '260px',
        }}
      />
    </div>
  )
}
