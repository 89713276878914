import { useMutation } from '@graphcommerce/graphql'
import { PROOFING_OPTION } from '@graphcommerce/graphql-mesh'
import { i18n } from '@lingui/core'
import { Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { ConfigurableCartItemOptionsFragment } from '../CartItems/CartItem/CartItem.gql'
import { FileUploadModal, ProofOptionEnum, s3File } from '../FileUpload/FileUploadModal'
import { Icon } from '../Layout/Icon'
import { ErrorPrompt } from '../Prompt/ErrorPrompt'
import { AttachArtworkDocument } from './AttachArtwork.gql'
import { DetachArtworkDocument } from './DetachArtwork.gql'
import { RemoveArtworkFab } from './RemoveArtworkFab'

export type CartItemProps = {
  artwork?: ConfigurableCartItemOptionsFragment['artwork']
  cartItemUid: string
  requiresArtwork?: boolean | null | undefined
  allowEdit?: boolean
  checkoutStyle?: boolean
}

export function ItemArtworkManager(props: CartItemProps) {
  const { artwork, cartItemUid, requiresArtwork = false, allowEdit = true, checkoutStyle } = props
  const [isDisplayFileUploadModal, setIsDisplayFileUploadModal] = useState<boolean>(false)
  const [replaceArtworkId, setReplaceArtworkId] = useState<string | null>(null)
  const [artworkFiles, setArtworkFiles] = useState<Array<s3File>>([])
  const [artworkComment, setArtworkComment] = useState(artwork?.at(0)?.comments ?? '')
  const [proofOption, setProofOption] = useState<ProofOptionEnum>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    ProofOptionEnum.print_asap.toString() === artwork?.at(0)?.proofing_option
      ? ProofOptionEnum.print_asap
      : ProofOptionEnum.wait_for_proof,
  )

  const [attachArtwork] = useMutation(AttachArtworkDocument, {
    refetchQueries: ['CartPage', 'ShippingPage', 'PaymentPage'],
  })
  const [detachArtwork] = useMutation(DetachArtworkDocument, {
    refetchQueries: ['CartPage', 'ShippingPage', 'PaymentPage'],
  })
  const [attachError, setAttachError] = useState<Error>()

  useEffect(() => {
    if (artworkFiles.length) {
      artworkFiles.forEach(async (artworkFile) => {
        if (!artworkFile?.artworkId) {
          await attachArtwork({
            variables: {
              cartItemUid,
              fileName: artworkFile.fileName ?? '',
              fileUrl: artworkFile.url ?? '',
              proofingOption:
                (proofOption.toString() as PROOFING_OPTION) ?? ('' as PROOFING_OPTION),
              comments: artworkComment ?? '',
            },
          }).catch((error: Error) => {
            setAttachError(error)
          })
        }
      })
      setArtworkFiles([])
    }
  }, [
    artworkFiles,
    replaceArtworkId,
    cartItemUid,
    attachArtwork,
    detachArtwork,
    proofOption,
    artworkComment,
  ])

  const artworkS3Files: Array<s3File> =
    artwork?.map((file) => ({
      fileName: file?.file_name,
      url: file?.file_url,
      size: undefined,
      uploadPercentage: 100,
      artworkId: file?.id,
    })) ?? []

  return (
    <div className='w-full md:max-w-[160px] xl:max-w-[200px]'>
      {allowEdit && (
        <div className='flex w-full items-center'>
          <button
            type='button'
            onClick={() => {
              setIsDisplayFileUploadModal(true)
              setReplaceArtworkId(null)
            }}
          >
            {/* <Icon
              name='add_box'
              className='mr-2 cursor-pointer align-middle text-xl text-dark-gray hover:text-material-ui-blue lg:text-3xl'
              onClick={() => {
                setIsDisplayFileUploadModal(true)
                setReplaceArtworkId(null)
              }}
            /> */}
            <span
              className={`${
                checkoutStyle
                  ? 'text-[12px] underline hover:text-material-ui-dark-blue hover:underline'
                  : 'text-[12px] italic text-material-ui-blue hover:text-material-ui-dark-blue hover:underline'
              }`}
            >
              {i18n._('Manage Artwork')}
            </span>
          </button>
        </div>
      )}
      {isDisplayFileUploadModal && (
        <FileUploadModal
          modalId='fileUploadModal'
          isDiplayed={isDisplayFileUploadModal}
          setIsDisplayed={setIsDisplayFileUploadModal}
          setUploadedFile={setArtworkFiles}
          files={artworkS3Files}
          proofOption={proofOption}
          setProofOption={setProofOption}
          artworkComment={artworkComment ?? null}
          setArtworkComment={setArtworkComment}
        />
      )}
      <ErrorPrompt error={attachError} />
    </div>
  )
}
