import { i18n } from '@lingui/core'
import { Modal } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { PrimaryButton, SecondaryButton } from '../Button'
import { SlideinModalHeader } from '../Layout/SlideinModalHeader'

export type ConfirmationPromptProps = {
  title: string
  message: string
  isDisplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
  setResponse: Dispatch<SetStateAction<boolean | undefined>>
  callback?: (
    ...args
  ) => void /** You can use this field when using useCallback instead of useEffect */
  callbackArgs?: any | any[]
}

export function ConfirmationPrompt(props: ConfirmationPromptProps) {
  const { title, message, isDisplayed, setIsDisplayed, setResponse, callback, callbackArgs } = props

  const handleClose = () => setIsDisplayed(false)

  const setResponseAndClose = (response: boolean) => {
    setResponse(response)
    if (callback) {
      if (callbackArgs instanceof Array) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        callback(response, ...callbackArgs)
      } else if (callbackArgs) {
        callback(response, callbackArgs)
      } else {
        callback(response)
      }
    }
    handleClose()
  }

  return (
    <Modal className='z-100' open={isDisplayed} onClose={handleClose}>
      <div className='z-100 absolute left-[5vw] top-[20vh] flex h-auto w-[90vw] flex-col items-center rounded-[4px] bg-pure-white shadow-md md:left-[30vw] md:w-[40vw]'>
        <SlideinModalHeader
          title={title}
          className='mb-[20px] w-full rounded-t-[4px]'
          titleClassName='Type-XXL-Bold'
          withLeftPadding
          withHorizontalMargin
        />
        <p className='mb-[40px] px-[20px]'>{message}</p>
        <div className='mb-[40px] flex w-[90%] max-w-[350px] flex-col items-center justify-center gap-y-5 md:w-[35%] md:max-w-none md:flex-row md:justify-between'>
          {/* <CancelButton
            type='button'
            className='w-full max-w-[350px] bg-[#F2F2F2] uppercase md:w-auto lg:w-[150px]'
            color='secondary'
            variant='contained'
            size='small'
            onClick={() => setResponseAndClose(true)}
          >
            {i18n._('Yes')}
          </CancelButton> */}

          {/* <CancelButton
            type='button'
            className='w-full max-w-[350px] bg-[#F2F2F2] uppercase md:w-auto lg:w-[150px]'
            color='secondary'
            variant='contained'
            size='small'
            onClick={() => setResponseAndClose(false)}
          >
            {i18n._('No')}
          </CancelButton> */}
          <SecondaryButton className='w-full md:w-auto' onClick={() => setResponseAndClose(false)}>
            {i18n._('No')}
          </SecondaryButton>
          <PrimaryButton
            type='button'
            className='w-full md:w-auto '
            onClick={() => setResponseAndClose(true)}
          >
            {i18n._('Yes')}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}
