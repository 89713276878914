import { CartItemsFragment } from '@graphcommerce/magento-cart-items/Api/CartItems.gql'
import { RenderType, TypeRenderer } from '@graphcommerce/next-ui'

export type CartItemRenderer = TypeRenderer<NonNullable<NonNullable<CartItemsFragment['items']>[0]>>

export type CartProps = {
  renderer: CartItemRenderer
  containerClass?: string
} & CartItemsFragment & {
    showSummary?: boolean
    displayAsCartItem?: boolean
    enableModifyArtwork?: boolean
  }

export function CartItems(props: CartProps) {
  const {
    renderer,
    containerClass,
    items,
    id,
    showSummary,
    displayAsCartItem,
    enableModifyArtwork,
  } = props

  return (
    <>
      {items?.map((item) => {
        if (!item?.uid || !id) return null
        return (
          <div key={item.uid} className={containerClass || ''}>
            <RenderType
              renderer={renderer}
              {...item}
              showSummary={showSummary}
              displayAsCartItem={displayAsCartItem}
              enableModifyArtwork={enableModifyArtwork}
            />
          </div>
        )
      })}
    </>
  )
}
