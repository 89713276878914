import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { RelatedProductGroups } from '../Amasty/RelatedProductGroups'
import { PrimaryButton } from '../Button'
import { Icon } from '../Layout/Icon'

type EmptyCartProps = {
  handleCloseMiniCart: () => void
}

export function EmptyCart(props: EmptyCartProps) {
  const { handleCloseMiniCart } = props

  return (
    <div className='flex min-w-[10vw] flex-col items-center justify-center'>
      <Icon name='shopping_bag' className='mt-[10vh] text-[55px]' />
      <span className='Type-H4-Bold mt-[30px]'>
        <Trans id='Your cart is empty' />
      </span>
      <PrimaryButton
        type='button'
        className='mb-[15vh] mt-[80px] w-full max-w-[350px] md:w-auto'
        onClick={() => handleCloseMiniCart()}
      >
        {i18n._('Continue Shopping')}
      </PrimaryButton>
      <RelatedProductGroups
        position='cart_content_bottom'
        uid=''
        classes={{ list: 'grid grid-cols-2 gap-[40px]' }}
        callback={handleCloseMiniCart}
        displayOnMinicart
      />
    </div>
  )
}
