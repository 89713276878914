import { Select, SxProps, styled } from '@mui/material'

export const StandardSelect = styled(Select)((sx?: SxProps) => ({
  lineHeight: '24px',
  height: '40px',
  '&.MuiFormLabel-root &.MuiInputLabel-root': {
    fontSize: '14px',
  },
}))

export const DualValueSelect = styled(Select)((sx?: SxProps) => ({
  lineHeight: '24px',
  height: '40px',
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '20px',
  },
}))

export const StandardRoundedSelect = styled(Select)((sx?: SxProps) => ({
  lineHeight: '36px',
  height: '52px',
  borderRadius: 8,
  '&.MuiFormLabel-root &.MuiInputLabel-root': {
    fontSize: '20px',
    color: 'gray',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingLeft: '4px',
    fontSize: '20px',
  },
  '& label.MuiInputLabel-shrink': {
    color: 'gray',
    fontSize: '20px',
  },
}))

export const DualValueRoundedSelect = styled(Select)((sx?: SxProps) => ({
  lineHeight: '24px',
  height: '52px',
  borderRadius: 8,
  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '20px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    borderWidth: '1px',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    fontSize: '20px',
    color: 'gray',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    paddingLeft: '4px',
    fontSize: '20px',
  },
  '& label.MuiInputLabel-shrink': {
    color: 'gray',
    fontSize: '20px',
  },
}))
